const urlsMap = {
  userRest: "api/users",
};
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const validatePhone = (phone) => {
  const re = /^(\+48|48)?\d{9}$/;
  return re.test(String(phone));
};

export { urlsMap, validateEmail, validatePhone };

export const weekDayMap = [
  "Poniedziałek",
  "Wtorek",
  "Środa",
  "Czwartek",
  "Piątek",
  "Sobota",
  "Niedziela",
];
