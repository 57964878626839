import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Paper,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Autocomplete, // Import Autocomplete
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import axios from "axios";

const UserPassesComponent = () => {
  const [usersWithPasses, setUsersWithPasses] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isUserListVisible, setIsUserListVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingPass, setEditingPass] = useState(null);
  const [editPassDialogOpen, setEditPassDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // State variables for adding pass
  const [passTypes, setPassTypes] = useState([]);
  const [lessonCategories, setLessonCategories] = useState([]);
  const [selectedPassType, setSelectedPassType] = useState(null);
  const [lessonCategoryCounts, setLessonCategoryCounts] = useState({});
  const [addPassDialogOpen, setAddPassDialogOpen] = useState(false);
  const [addPassError, setAddPassError] = useState("");
  const [addPassSuccess, setAddPassSuccess] = useState("");
  const [isAddingPass, setIsAddingPass] = useState(false);

  // State variables for duration
  const [durationValue, setDurationValue] = useState("");
  const [durationUnit, setDurationUnit] = useState("days");

  // State variable for filtering users with at least one paid pass
  const [showPaidUsersOnly, setShowPaidUsersOnly] = useState(false);

  // New state variables
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUserForPass, setSelectedUserForPass] = useState(null);

  useEffect(() => {
    fetchPassTypesAndLessonCategories();
  }, []);

  const fetchPassTypesAndLessonCategories = async () => {
    try {
      const [passTypeResponse, lessonCategoryResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/pass-type/`, {
          headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
        }),
        axios.get(`${process.env.REACT_APP_BACKEND_ADDRESS}/lesson-category/`, {
          headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
        }),
      ]);

      if (
        passTypeResponse.status === 200 &&
        Array.isArray(passTypeResponse.data.objects)
      ) {
        setPassTypes(passTypeResponse.data.objects);
      } else {
        throw new Error("Failed to fetch pass types");
      }

      if (
        lessonCategoryResponse.status === 200 &&
        Array.isArray(lessonCategoryResponse.data.objects)
      ) {
        setLessonCategories(lessonCategoryResponse.data.objects);
      } else {
        throw new Error("Failed to fetch lesson categories");
      }
    } catch (error) {
      console.error("Error fetching pass types and lesson categories:", error);
    }
  };

  const handleUserPassesClick = async () => {
    try {
      setIsUserListVisible((prev) => !prev);

      if (usersWithPasses.length === 0) {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/user/passes`,
          { headers: { "Access-Token": process.env.REACT_APP_API_TOKEN } }
        );

        const data = response.data.users;

        // Consolidate passes for users with the same user_id
        const usersMap = {};

        data.forEach((entry) => {
          const fullName = entry.full_name;
          const passes = entry.passes; // 'passes' is an array

          // Assuming all passes in 'entry.passes' belong to the same user
          const userId = passes[0]?.user_id;

          if (!usersMap[userId]) {
            usersMap[userId] = {
              user_id: userId,
              full_name: fullName,
              passes: [],
            };
          }

          usersMap[userId].passes = usersMap[userId].passes.concat(passes);
        });

        const usersArray = Object.values(usersMap);
        setUsersWithPasses(usersArray);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch users with active passes:", error);
      setLoading(false);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const handleEditPass = (pass) => {
    setEditingPass(pass);
    setEditPassDialogOpen(true);
  };

  const handleDeletePass = (pass) => {
    if (window.confirm("Czy na pewno chcesz usunąć ten karnet?")) {
      deletePass(pass.id);
    }
  };

  const deletePass = async (passId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass/${passId}`,
        {
          headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
        }
      );
      // Remove the deleted pass from the selectedUser.passes
      setSelectedUser((prev) => ({
        ...prev,
        passes: prev.passes.filter((pass) => pass.id !== passId),
      }));
      // Update usersWithPasses state
      setUsersWithPasses((prevUsers) =>
        prevUsers.map((user) =>
          user.user_id === selectedUser.user_id
            ? {
                ...user,
                passes: user.passes.filter((pass) => pass.id !== passId),
              }
            : user
        )
      );
    } catch (error) {
      console.error("Failed to delete pass:", error);
    }
  };

  const handleSaveEditPass = async () => {
    try {
      setIsUpdating(true);

      // Create a copy of editingPass to modify
      const passToUpdate = { ...editingPass };

      // Convert valid_until to ISO datetime format if it exists
      if (passToUpdate.valid_until) {
        // If valid_until is in 'YYYY-MM-DD' format, convert it to ISO datetime
        const date = new Date(passToUpdate.valid_until);
        passToUpdate.valid_until = date.toISOString();
      }

      await axios.put(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass/${editingPass.id}`,
        passToUpdate,
        {
          headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
        }
      );

      // Update the selectedUser.passes with the edited pass
      setSelectedUser((prev) => ({
        ...prev,
        passes: prev.passes.map((pass) =>
          pass.id === editingPass.id ? { ...editingPass } : pass
        ),
      }));

      // Update usersWithPasses state
      setUsersWithPasses((prevUsers) =>
        prevUsers.map((user) =>
          user.user_id === selectedUser.user_id
            ? {
                ...user,
                passes: user.passes.map((pass) =>
                  pass.id === editingPass.id ? { ...editingPass } : pass
                ),
              }
            : user
        )
      );

      setEditPassDialogOpen(false);
      setEditingPass(null);
      setIsUpdating(false);
    } catch (error) {
      console.error("Failed to update pass:", error);
      setIsUpdating(false);
    }
  };

  const handlePassTypeChange = (event) => {
    const selectedPass = event.target.value;
    setSelectedPassType(selectedPass);

    // Reset lessons count for new pass type
    const newLessonsCount = {};
    selectedPass.lesson_categories_for_pass_type.forEach((category) => {
      newLessonsCount[category.lesson_category_id] = category.lessons_to_use;
    });
    setLessonCategoryCounts(newLessonsCount);
  };

  const handleLessonCountChange = (lessonCategoryId, count) => {
    setLessonCategoryCounts((prevState) => ({
      ...prevState,
      [lessonCategoryId]: count,
    }));
  };

  const getLessonCategoryName = (categoryId) => {
    const category = lessonCategories.find((cat) => cat.id === categoryId);
    return category ? category.name : `ID: ${categoryId}`;
  };

  const handleAddPass = async () => {
    setIsAddingPass(true);
    setAddPassError("");
    setAddPassSuccess("");

    try {
      if (!durationValue || parseInt(durationValue, 10) <= 0) {
        setAddPassError("Proszę wprowadzić poprawną długość trwania.");
        setIsAddingPass(false);
        return;
      }

      const lessonsCount = Object.keys(lessonCategoryCounts).map((key) => ({
        lesson_category_id: parseInt(key, 10),
        lessons_to_use: parseInt(lessonCategoryCounts[key], 10),
      }));

      const valid_from = new Date(); // Current date and time

      // Calculate valid_until by adding durationValue and durationUnit to valid_from
      let valid_until = new Date(valid_from);

      const durationVal = parseInt(durationValue, 10);

      if (durationUnit === "days") {
        valid_until.setDate(valid_until.getDate() + durationVal);
      } else if (durationUnit === "weeks") {
        valid_until.setDate(valid_until.getDate() + durationVal * 7);
      } else if (durationUnit === "months") {
        valid_until.setMonth(valid_until.getMonth() + durationVal);
      }

      // Convert valid_from and valid_until to ISO strings
      const valid_from_iso = valid_from.toISOString();
      const valid_until_iso = valid_until.toISOString();

      const userIdToUse = selectedUser
        ? selectedUser.user_id
        : selectedUserForPass
        ? selectedUserForPass.id
        : null;

      if (!userIdToUse) {
        setAddPassError("Proszę wybrać użytkownika.");
        setIsAddingPass(false);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass_to_user/`,
        {
          pass_type_name: selectedPassType.name,
          user_id: userIdToUse,
          lesson_categories: lessonsCount,
          valid_from: valid_from_iso,
          valid_until: valid_until_iso,
          payed: true
        },
        {
          headers: {
            "Access-Token": process.env.REACT_APP_API_TOKEN,
          },
        }
      );

      if (response.status === 200) {
        setAddPassSuccess("Karnet został dodany pomyślnie!");
        setAddPassError("");

        // Update the selectedUser.passes with the new pass if selectedUser is set
        if (selectedUser && selectedUser.user_id === userIdToUse) {
          setSelectedUser((prev) => ({
            ...prev,
            passes: [...prev.passes, response.data],
          }));

          // Update usersWithPasses state
          setUsersWithPasses((prevUsers) =>
            prevUsers.map((user) =>
              user.user_id === selectedUser.user_id
                ? {
                    ...user,
                    passes: [...user.passes, response.data],
                  }
                : user
            )
          );
        }

        handleCloseAddPassDialog();
      } else {
        setAddPassError("Nie udało się dodać karnetu.");
        setAddPassSuccess("");
      }
    } catch (error) {
      console.error("Failed to add pass:", error);
      setAddPassError("Nie udało się dodać karnetu.");
    } finally {
      setIsAddingPass(false);
    }
  };

  const handleCloseAddPassDialog = () => {
    setAddPassDialogOpen(false);
    setSelectedPassType(null);
    setLessonCategoryCounts({});
    setAddPassError("");
    setAddPassSuccess("");
    setDurationValue("");
    setDurationUnit("days");
    setSelectedUserForPass(null);
  };

  // Function to fetch all users
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user`,
        {
          headers: { "Access-Token": process.env.REACT_APP_API_TOKEN },
        }
      );

      if (response.status === 200 && Array.isArray(response.data.objects)) {
        setAllUsers(response.data.objects);
      } else {
        throw new Error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Function to open Add Pass Dialog and fetch users if needed
  const handleOpenAddPassDialog = () => {
    setAddPassDialogOpen(true);
    if (allUsers.length === 0) {
      fetchAllUsers();
    }
  };

  const userColumns = [
    { field: "full_name", headerName: "Użytkownik", flex: 1 },
  ];

  const passColumns = [
    { field: "pass_type_name", headerName: "Typ Karnetu", flex: 1 },
    {
      field: "active",
      headerName: "Aktywny",
      flex: 0.5,
      renderCell: (params) => (params.value ? "Tak" : "Nie"),
    },
    {
      field: "payed",
      headerName: "Opłacony",
      flex: 0.5,
      renderCell: (params) => (params.value ? "Tak" : "Nie"),
    },
    {
      field: "actions",
      headerName: "Akcje",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => handleEditPass(params.row)}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeletePass(params.row)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const filteredUsers = usersWithPasses.filter((user) => {
    const nameMatches = user.full_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const hasPaidPass = user.passes.some((pass) => pass.payed);
    return nameMatches && (!showPaidUsersOnly || hasPaidPass);
  });

  return (
    <Box>
      <Typography variant="h6">
        <div className="section-title">
          <a onClick={handleUserPassesClick} style={{ cursor: "pointer" }}>
            Użytkownicy z aktywnymi karnetami
            <i
              className={`bx ${
                isUserListVisible ? "bx-chevron-up" : "bx-chevron-down"
              } icon-show`}
            ></i>
          </a>
        </div>
      </Typography>

      {/* Add Pass to Any User Button */}
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleOpenAddPassDialog}
          color="primary"
          variant="contained"
        >
          Dodaj Karnet Użytkownikowi
        </Button>
      </Box>

      {isUserListVisible && (
        <>
          {loading ? (
            <Typography>Ładowanie danych...</Typography>
          ) : usersWithPasses.length > 0 ? (
            <Box mt={2}>
              <TextField
                label="Szukaj użytkownika"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPaidUsersOnly}
                    onChange={(e) => setShowPaidUsersOnly(e.target.checked)}
                    color="primary"
                  />
                }
                label="Pokaż tylko użytkowników z opłaconym karnetem"
              />

              <Paper style={{ height: 500, width: "100%" }}>
                <DataGrid
                  rows={filteredUsers}
                  columns={userColumns}
                  pageSize={10}
                  rowsPerPageOptions={[10, 25, 50]}
                  getRowId={(row) => row.user_id}
                  onRowClick={(params) => handleUserClick(params.row)}
                />
              </Paper>
            </Box>
          ) : (
            <Typography>Brak użytkowników z aktywnymi karnetami.</Typography>
          )}
        </>
      )}

      {/* Dialog showing passes for the selected user */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Karnety użytkownika: {selectedUser?.full_name}
        </DialogTitle>
        <DialogContent>
          {selectedUser?.passes && selectedUser.passes.length > 0 ? (
            <Paper style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={selectedUser.passes}
                columns={passColumns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 25]}
                getRowId={(row) => row.id}
              />
            </Paper>
          ) : (
            <Typography>Brak karnetów dla tego użytkownika.</Typography>
          )}
          {/* Add Pass Button */}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={() => setAddPassDialogOpen(true)}
              color="primary"
              variant="contained"
            >
              Dodaj Karnet
            </Button>
          </Box>
        </DialogContent>
        <Button onClick={handleCloseDialog} color="error">
          Zamknij
        </Button>
      </Dialog>

      {/* Dialog for adding pass */}
      <Dialog
        open={addPassDialogOpen}
        onClose={handleCloseAddPassDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Dodaj nowy karnet</DialogTitle>
        <DialogContent>
          {/* User Selection */}
          {!selectedUser && (
            <Autocomplete
              options={allUsers}
              getOptionLabel={(option) => option.full_name}
              onChange={(event, newValue) => {
                setSelectedUserForPass(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Wybierz Użytkownika"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
            />
          )}

          {/* Pass Type Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="pass-select-label">Wybierz karnet</InputLabel>
            <Select
              labelId="pass-select-label"
              value={selectedPassType}
              onChange={handlePassTypeChange}
              label="Wybierz karnet"
            >
              {passTypes.map((pass) => (
                <MenuItem key={pass.id} value={pass}>
                  {pass.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Lesson Categories */}
          {selectedPassType &&
            selectedPassType.lesson_categories_for_pass_type.map((category) => (
              <FormControl
                fullWidth
                sx={{ mb: 2 }}
                key={category.lesson_category_id}
              >
                <TextField
                  label={`Liczba lekcji dla kategorii: ${getLessonCategoryName(
                    category.lesson_category_id
                  )}`}
                  type="number"
                  variant="outlined"
                  value={
                    lessonCategoryCounts[category.lesson_category_id] || ""
                  }
                  onChange={(e) =>
                    handleLessonCountChange(
                      category.lesson_category_id,
                      e.target.value
                    )
                  }
                  fullWidth
                />
              </FormControl>
            ))}

          {/* Duration Selection */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Długość trwania"
              type="number"
              variant="outlined"
              value={durationValue}
              onChange={(e) => setDurationValue(e.target.value)}
              inputProps={{ min: 1 }}
              fullWidth
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="duration-unit-label">Jednostka</InputLabel>
            <Select
              labelId="duration-unit-label"
              value={durationUnit}
              onChange={(e) => setDurationUnit(e.target.value)}
              label="Jednostka"
            >
              <MenuItem value="days">Dni</MenuItem>
              <MenuItem value="weeks">Tygodnie</MenuItem>
              <MenuItem value="months">Miesiące</MenuItem>
            </Select>
          </FormControl>

          {addPassError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {addPassError}
            </Alert>
          )}
          {addPassSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {addPassSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddPassDialog} color="error">
            Anuluj
          </Button>
          <Button
            onClick={handleAddPass}
            color="primary"
            disabled={
              isAddingPass ||
              !selectedPassType ||
              !durationValue ||
              !durationUnit ||
              parseInt(durationValue, 10) <= 0 ||
              (!selectedUser && !selectedUserForPass)
            }
          >
            {isAddingPass ? <CircularProgress size={24} /> : "Dodaj Karnet"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing pass */}
      <Dialog
        open={editPassDialogOpen}
        onClose={() => setEditPassDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edytuj Karnet</DialogTitle>
        <DialogContent>
          {/* Active Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={editingPass?.active || false}
                onChange={(e) =>
                  setEditingPass((prev) => ({
                    ...prev,
                    active: e.target.checked,
                  }))
                }
                color="primary"
              />
            }
            label="Aktywny"
          />

          {/* Pass Type Name */}
          <TextField
            label="Typ Karnetu"
            variant="outlined"
            value={editingPass?.pass_type_name || ""}
            onChange={(e) =>
              setEditingPass((prev) => ({
                ...prev,
                pass_type_name: e.target.value,
              }))
            }
            fullWidth
            margin="normal"
          />

          {/* Valid Until */}
          <TextField
            label="Ważny do"
            variant="outlined"
            type="date"
            value={
              editingPass?.valid_until
                ? editingPass.valid_until.split("T")[0]
                : ""
            }
            onChange={(e) => {
              const dateValue = e.target.value;
              setEditingPass((prev) => ({
                ...prev,
                valid_until: dateValue,
              }));
            }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          {/* Editing lesson categories */}
          {editingPass?.lesson_categories_for_pass &&
            editingPass.lesson_categories_for_pass.length > 0 && (
              <Box mt={2}>
                <Typography variant="h6">Kategorie Lekcji</Typography>
                {editingPass.lesson_categories_for_pass.map(
                  (category, index) => (
                    <Box key={index} mt={1}>
                      <TextField
                        label="Nazwa Kategorii"
                        variant="outlined"
                        value={category.name || ""}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <TextField
                        label="Pozostałe Lekcje"
                        variant="outlined"
                        type="number"
                        value={category.remaining_lessons}
                        onChange={(e) => {
                          const newRemaining = parseInt(e.target.value, 10);
                          if (!isNaN(newRemaining)) {
                            setEditingPass((prev) => {
                              const newCategories = [
                                ...prev.lesson_categories_for_pass,
                              ];
                              newCategories[index].remaining_lessons =
                                newRemaining;
                              return {
                                ...prev,
                                lesson_categories_for_pass: newCategories,
                              };
                            });
                          }
                        }}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Zużyte Lekcje"
                        variant="outlined"
                        type="number"
                        value={category.consumed_lessons}
                        onChange={(e) => {
                          const newConsumed = parseInt(e.target.value, 10);
                          if (!isNaN(newConsumed)) {
                            setEditingPass((prev) => {
                              const newCategories = [
                                ...prev.lesson_categories_for_pass,
                              ];
                              newCategories[index].consumed_lessons =
                                newConsumed;
                              return {
                                ...prev,
                                lesson_categories_for_pass: newCategories,
                              };
                            });
                          }
                        }}
                        fullWidth
                        margin="normal"
                      />
                    </Box>
                  )
                )}
              </Box>
            )}
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center" m={2}>
          <Button
            onClick={() => setEditPassDialogOpen(false)}
            color="error"
            variant="outlined"
            style={{ marginRight: "8px" }}
            disabled={isUpdating}
          >
            Anuluj
          </Button>
          <Button
            onClick={handleSaveEditPass}
            color="primary"
            variant="contained"
            disabled={isUpdating}
          >
            {isUpdating ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Zapisz"
            )}
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default UserPassesComponent;
