import React, { useState } from "react";
import { DeleteUsers as LessonManagement } from "./LessonAttendies";
import Payments from "./components/Admin/Payments";
import UserPassesComponent from "./components/Admin/UserPassesComponent";
import "./assets/style/Admin.css";

const Admin = () => {
  const [isUserListOpen, setUserListOpen] = useState(false);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [isPassManagementOpen, setPassManagementOpen] = useState(false); // State for pass management tab

  const handleOpenUserList = (lessonId) => {
    setSelectedLessonId(lessonId);
    setUserListOpen(true);
  };

  const handleOpenPassManagement = () => {
    setPassManagementOpen(true);
  };

  return (
    <section id="admin" className="admin">
      <div className="row">
        <div
          className="col-lg-12 pt-4 pt-lg-0 content"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div className="accordion-list">
            <ul>
              <li key="lessonAttendies">
                <LessonManagement onAddUser={handleOpenUserList} />
              </li>
              <li key="payments">
                <Payments></Payments>
              </li>
              <li key="userPassesComponent">
                <UserPassesComponent />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Admin;
