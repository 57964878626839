import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import axios from "axios";
import { useAuthStore } from "../../store"; // Adjust the path as necessary

const UserListForLesson = ({ lessonId, onUserAdded, open, onClose }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [passes, setPasses] = useState([]);
  const [selectedPass, setSelectedPass] = useState(null);

  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user/?sort=full_name`,
        { headers: { "Access-Token": process.env.REACT_APP_API_TOKEN } }
      );
      if (response.status !== 200) {
        console.error("Failed to fetch users", response);
        return;
      }
      if (Array.isArray(response.data.objects)) {
        setUsers(response.data.objects);
        setFilteredUsers(response.data.objects);
      } else {
        console.error("Fetched data is not an array");
      }
    } catch (error) {
      console.error("Failed to fetch users", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPasses = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/pass/all_for_admin/?user_id=${userId}&lesson_id=${lessonId}`,
        { headers: { "Access-Token": process.env.REACT_APP_API_TOKEN } }
      );
      if (response.status !== 200) {
        console.error("Failed to fetch passes", response);
        return;
      }
      if (Array.isArray(response.data.objects)) {
        setPasses(response.data.objects);
      } else {
        console.error("Fetched passes data is not an array");
      }
    } catch (error) {
      console.error("Failed to fetch passes", error);
    }
  };

  const handleUserChange = (event, value) => {
    if (value) {
      setSelectedUser(value.id);
      fetchPasses(value.id);
    } else {
      setSelectedUser(null);
      setPasses([]);
    }
  };

  const handleAddUserToLesson = async () => {
    setLoading(true);
    setError("");
    try {
      const selectedUserData = users.find((u) => u.id === selectedUser);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/user_to_lesson/`,
        {
          email: selectedUserData.email,
          phone: selectedUserData.phone,
          name: selectedUserData.full_name.split(" ")[0],
          surname: selectedUserData.full_name.split(" ")[1],
          lesson_id: lessonId,
          full_name: selectedUserData.full_name,
          pass_id: selectedPass,
        },
        {
          headers: {
            "Access-Token": process.env.REACT_APP_API_TOKEN,
          },
        }
      );
      onUserAdded(selectedUserData); // Pass the full user data back
      handleClose();
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("User already signed up for this lesson");
      } else {
        setError("Failed to add user to lesson");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedUser(null);
    setSelectedPass(null);
    setPasses([]);
    setError("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: { height: "80vh" },
      }}
    >
      <DialogTitle>Add User to Lesson</DialogTitle>
      <DialogContent dividers>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Autocomplete
            options={filteredUsers}
            getOptionLabel={(option) => option.full_name}
            renderOption={(props, option) => (
              <Box component="li" {...props} sx={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="body1">{option.full_name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {option.email}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select User"
                variant="outlined"
                fullWidth
              />
            )}
            onChange={handleUserChange}
            fullWidth
          />
        </FormControl>
        {selectedUser && passes.length > 0 && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="pass-select-label">Select Pass</InputLabel>
            <Select
              labelId="pass-select-label"
              value={selectedPass}
              onChange={(e) => setSelectedPass(e.target.value)}
              label="Select Pass"
              fullWidth
            >
              <MenuItem value={null}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">None</Typography>
                </Box>
              </MenuItem>
              {passes.map((pass) => (
                <MenuItem key={pass.id} value={pass.id}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1">
                      {pass.pass_type_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Ważny do:{" "}
                      {new Date(pass.valid_until).toLocaleDateString()} -{" "}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
        <Button
          onClick={handleAddUserToLesson}
          color="primary"
          disabled={loading || !selectedUser}
        >
          {loading ? <CircularProgress size={24} /> : "Add User"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserListForLesson;
