import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { pl } from "date-fns/locale";
import axios from "axios";
import "../../assets/style/LessonsTable.css"; // Import the CSS file for custom styling
import { useAuthStore } from "../../store";

const LessonsTable = () => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentLessonId, setPaymentLessonId] = useState(null);
  const [acceptRegulamin, setAcceptRegulamin] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [signOutConfirmationOpen, setSignOutConfirmationOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [signingOut, setSigningOut] = useState(false); // New state for sign-out loading
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { user, setLessons: updateLessonsInStore } = useAuthStore((state) => ({
    user: state.user,
    setLessons: state.setLessons,
  }));

  useEffect(() => {
    const fetchLessons = async () => {
      const idToken = await user.getIdToken();
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_ADDRESS}/user/me/lessons`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const lessons = response.data.objects;
        setLessons(lessons);
        updateLessonsInStore(lessons); // Update lessons in the store
      } catch (error) {
        setError("Failed to fetch lessons");
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, [user, updateLessonsInStore]);

  const formatDate = (lesson) => {
    const parsedDate = new Date(lesson.start);
    if (!isValid(parsedDate)) {
      return {
        day: "Invalid Date",
        date: "Invalid Date",
        start: "Invalid Time",
        end: "Invalid Time",
      };
    }
    const day = format(parsedDate, "EEEE", { locale: pl });
    const date = format(parsedDate, "dd-MM-yyyy", { locale: pl });
    const start = format(parsedDate, "HH:mm", { locale: pl });
    const end = format(
      new Date(parsedDate.getTime() + lesson.duration * 1000),
      "HH:mm",
      { locale: pl }
    );
    return { day, date, start, end };
  };

  const handleOpenConfirmation = (lessonId) => {
    setPaymentLessonId(lessonId);
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setAcceptRegulamin(false);
    setPaymentLessonId(null);
  };

  const handleOpenSignOutConfirmation = (lessonId) => {
    setPaymentLessonId(lessonId);
    setSignOutConfirmationOpen(true);
  };

  const handleCloseSignOutConfirmation = () => {
    setSignOutConfirmationOpen(false);
    setPaymentLessonId(null);
  };

  const handlePayment = async () => {
    if (!acceptRegulamin) {
      alert("Proszę zaakceptować Regulamin.");
      return;
    }

    const idToken = await user.getIdToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/payment/create_order`,
        { lesson_id: paymentLessonId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const redirectUrl = response.data;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Payment failed", error);
      alert("Nie udało się dokonać płatności.");
    } finally {
      handleCloseConfirmation();
    }
  };

  const handleSignOut = async () => {
    if (!paymentLessonId) {
      console.error("Lesson ID is null");
      setSnackbarMessage("Invalid lesson ID.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setSigningOut(true); // Set loading to true when signing out begins
    const idToken = await user.getIdToken();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}/sign_me_out/`,
        { lesson_id: paymentLessonId },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage(
          "Wypisano się z zajęć! Zajęcia zostaną zwrócone do karnetu. Jeśli zajęcia zostały kupione bez karnetu, zostaną zwrócone do karnetu 'Odrabianie zajęć'."
        );
        setSnackbarSeverity("success");
        const updatedLessons = lessons.filter(
          (lesson) => lesson.id !== paymentLessonId
        );
        setLessons(updatedLessons);
        updateLessonsInStore(updatedLessons);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.detail === "24_hours_only"
      ) {
        setSnackbarMessage(
          "Można wypisać się z zajęć tylko 24 godziny przed ich rozpoczęciem."
        );
      } else {
        console.error("Error signing out of the lesson", error);
        setSnackbarMessage("Failed to sign out of the lesson.");
      }
      setSnackbarSeverity("error");
    } finally {
      setSigningOut(false); // Set loading to false when signing out finishes
      setSnackbarOpen(true);
      handleCloseSignOutConfirmation();
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {isMobile ? (
        <Paper
          sx={{
            backgroundColor: "#FAF3E0",
            padding: 2,
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          <List>
            {lessons.map((lesson) => {
              const { day, date, start, end } = formatDate(lesson);
              return (
                <React.Fragment key={lesson.id}>
                  <ListItem>
                    <ListItemText
                      primary={lesson.lesson_type.friendly_name}
                      secondary={
                        <>
                          <Typography component="span" variant="body2">
                            {day}, {date}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2">
                            Godzina: {start} - {end}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Box textAlign="center" mt={1} mb={1}>
                    {!lesson.payed && (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        sx={{ mb: 1 }} // Adds margin below the button
                        onClick={() => handleOpenConfirmation(lesson.id)}
                      >
                        Opłać zajęcia
                      </Button>
                    )}
                    <Button
                      color="error"
                      variant="contained"
                      fullWidth
                      onClick={() => handleOpenSignOutConfirmation(lesson.id)}
                      disabled={signingOut}
                    >
                      {signingOut && lesson.id === paymentLessonId ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Wypisz z zajęć"
                      )}
                    </Button>
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </Paper>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "70vh",
            overflowY: "auto",
            backgroundColor: "#FAF3E0",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="lesson-type">Rodzaj Lekcji</TableCell>
                <TableCell className="day">Dzień</TableCell>
                <TableCell className="date">Data</TableCell>
                <TableCell className="time">Godzina</TableCell>
                <TableCell className="actions">Opłać</TableCell>
                <TableCell className="actions">Wypisz</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lessons.map((lesson) => {
                const { day, date, start, end } = formatDate(lesson);
                return (
                  <TableRow key={lesson.id}>
                    <TableCell className="lesson-type">
                      {lesson.lesson_type.friendly_name}
                    </TableCell>
                    <TableCell className="day">{day}</TableCell>
                    <TableCell className="date">{date}</TableCell>
                    <TableCell className="time">
                      {start} - {end}
                    </TableCell>
                    <TableCell className="actions">
                      {!lesson.payed && !lesson.is_free && (
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ fontSize: "0.8rem", padding: "5px 10px" }}
                          onClick={() => handleOpenConfirmation(lesson.id)}
                        >
                          Opłać zajęcia
                        </Button>
                      )}
                    </TableCell>
                    <TableCell className="actions">
                      <Button
                        color="error"
                        variant="contained"
                        sx={{ fontSize: "0.8rem", padding: "5px 10px" }}
                        onClick={() => handleOpenSignOutConfirmation(lesson.id)}
                        disabled={signingOut}
                      >
                        {signingOut && lesson.id === paymentLessonId ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Wypisz z zajęć"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={confirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitle>Czy chcesz zapłacić z za zajęcia?</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptRegulamin}
                onChange={(e) => setAcceptRegulamin(e.target.checked)}
                name="acceptRegulamin"
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                Akceptuję{" "}
                <a
                  href={`${process.env.PUBLIC_URL}/statute`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Regulamin
                </a>
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlePayment}
            style={{
              backgroundColor: acceptRegulamin ? "green" : "grey",
              color: "white",
            }}
            disabled={!acceptRegulamin} // Disable button if regulamin not accepted
          >
            Tak
          </Button>
          <Button
            onClick={handleCloseConfirmation}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Nie
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={signOutConfirmationOpen}
        onClose={handleCloseSignOutConfirmation}
      >
        <DialogTitle>Czy na pewno chcesz się wypisać z zajęć?</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Zajęcia zostaną zwrócone do karnetu. Jeśli zajęcia zostały kupione
            bez karnetu, zostaną zwrócone do karnetu "Odrabianie zajęć".
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSignOut}
            style={{ backgroundColor: "green", color: "white" }}
            disabled={signingOut}
          >
            {signingOut ? <CircularProgress size={24} /> : "Tak"}
          </Button>
          <Button
            onClick={handleCloseSignOutConfirmation}
            style={{ backgroundColor: "red", color: "white" }}
            disabled={signingOut}
          >
            Nie
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Center the Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LessonsTable;
